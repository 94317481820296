<template lang="pug">
  .table
    AppTable.app-table(:columns="columns" :data="listData" border)
        template(slot-scope="{ row, index }" slot="image")
          Avatar(
            shape="square"
            :src="getMetaThumbnail(row.key, type)"
            size="large"
          )
        template(slot-scope="{ row, index }" slot="name")
          span {{ row.data.name }}
        template(slot-scope="{ row, index }" slot="description")
          span {{ row.data.description }}
        template(slot-scope="{ row, index }" slot="percentage")
          span {{ row.percentage }}%
        template(slot-scope="{ row, index }" slot="unlockCondition")
          div.unlock-condition(v-if="parseInt(row.data.unlock.level)")
            Tag(color="primary") {{ $t('LV{level}', { level: row.data.unlock.level })}}
          div.unlock-condition(v-if="parseInt(row.data.unlock.price)")
            Avatar(shape="square" size="small" src="/img/coin.png" style="margin-right: 8px")
            span {{ row.data.unlock.price }}
          div.unlock-condition(v-if="parseInt(row.data.unlock.gem)")
            Avatar(shape="square" size="small" src="/img/gem.png" style="margin-right: 8px")
            span {{ row.data.unlock.gem }}
          div.unlock-condition(v-if="parseInt(row.data.unlock.actPoint)")
            Avatar(shape="square" size="small" src="/img/actPoint.png" style="margin-right: 8px")
            span {{ row.data.unlock.actPoint }}
          div.unlock-condition(v-if="parseInt(row.data.unlock.laijiCoin)")
            Avatar(shape="square" size="small" src="/img/laijiCoin.png" style="margin-right: 8px")
            span {{ row.data.unlock.laijiCoin }}
        template(slot-scope="{ row, index }" slot="actions")
          Button.actions(
            type="success"
            size="small"
            icon="md-create"
            @click="showMetaEditor(row)"
          )
    MetaEditor(
      :form-data="metaData"
      edit-type="edit"
      :visible="metaEditorVisible"
      :on-update="onMetaUpdate"
      :on-close="closeMetaEditor"
    )
</template>
<script>
import AppTable from '~c/app-table'
import { mapActions, mapGetters } from 'vuex'
import MetaEditor from './meta-editor'
import to from 'await-to-js'
import Thumbnail from '~m/thumbnail'

export default {
  name: 'meta-table',
  mixins: [Thumbnail],
  components: {
    MetaEditor,
    AppTable
  },
  props: {
    listData: Array
  },
  data () {
    return {
      metaData: null,
      metaEditorVisible: false,
      showDeleteWarning: false
    }
  },
  computed: {
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    isSystem () {
      return this.$route.params.metaType === 'template'
    },
    columns () {
      let columns = [
        {
          title: this.$t('ID'),
          key: 'key',
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('Image'),
          slot: 'image',
          width: 200,
          align: 'center'
        },
        {
          title: this.$t('Name'),
          slot: 'name',
          width: 200,
          align: 'center'
        },
        {
          title: this.$t('Description'),
          slot: 'description'
        },
        {
          title: this.$t('Unlock condition'),
          slot: 'unlockCondition'
        },
        {
          title: this.$t('lottery percentage'),
          slot: 'percentage',
          width: 120,
          align: 'center'
        },
        {
          title: this.$t('Actived'),
          width: 100,
          render: this.renderSwitch,
          align: 'center'
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 90,
          align: 'center'
        }
      ]

      columns = columns.filter(c => c.remove !== true)
      return columns
    },

    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions('meta', {
      findMeta: 'find',
      metaUpdate: 'update',
      resetMeta: 'reset'
    }),
    ...mapActions('animation', {
      findAllAnimation: 'findAll'
    }),
    showMetaEditor (row) {
      let data = _.cloneDeep(row)

      this.metaData = {
        ...data
      }

      if (this.metaData.data.meta) this.metaData.data.meta = JSON.stringify(this.metaData.data.meta)
      this.metaEditorVisible = true
    },
    async onMetaUpdate () {
      let data = _.pick(this.metaData, [
        'data',
        '_id',
        'thumbnail',
        'thumbnail2',
        'thumbnail3',
        'totalRate'
      ])

      data.type = this.type

      let meta = data.data.meta
      if (meta) {
        data.data.meta = JSON.parse(meta)
      }

      let [err] = await to(this.metaUpdate({ id: data._id, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Update meta error'))
      }
      this.$Message.success(this.$t('Update meta successful'))
      this.closeMetaEditor()
    },
    closeMetaEditor () {
      this.metaEditorVisible = false
      setTimeout(() => {
        this.metaData = null
      }, 500)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('meta/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' }, trueValue: 'Enabled' }),
        h('Icon', { slot: 'close', props: { type: 'md-close' }, falseValue: 'Disabled' })
      ])
    }
  }
}
</script>
<style lang="sass">
.in-progress
  color: #ed4014
.finished
  color: #19be6b
.received, .ready
  color: #808695
.unlock-condition
  float: left
  width: auto
  padding-right: 6px
</style>
