<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isMetaBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Input.app-tool(
          v-model="search"
          icon="ios-search"
          clearable
          :placeholder="$t('Search name')"
          @on-change='onSearchInputChange'
        )

        Button.app-tool-right(
          type="primary",
          icon="md-add",
          @click="showMetaEditor"
        ) {{ $t('Add new') }}

    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24' v-if="metaList.length || !metaListLoading")
        MetaTable(
          :list-data="metaList",
        )

      Col(span='24', class="table-loading-col", v-if="metaListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

    MetaEditor(
      :form-data="metaData"
      edit-type="new"
      :visible="metaEditorVisible"
      :on-update="onMetaCreate"
      :on-close="closeMetaEditor"
    )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MetaTable from './meta-table'
import MetaEditor from './meta-editor'
import Scroll from '~m/scroll'
import to from 'await-to-js'

export default {
  name: 'meta-list',
  components: {
    MetaTable,
    MetaEditor
  },
  mixins: [Scroll],
  data () {
    return {
      metaData: null,
      metaEditorVisible: false,
      filter: null,
      sidx: 'data.id',
      search: ''
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      this.resetSelector()
    }
  },

  computed: {
    ...mapGetters('meta', {
      metaList: 'getListData',
      metaListLoading: 'getLoadingStatus',
      hasNextMeta: 'hasNext'
    }),

    // 遊戲內容是否在讀取
    isMetaBusy () {
      return this.metaListLoading || !this.hasNextMeta
    },

    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  methods: {
    ...mapActions('game', { getAllGames: 'findAll' }),
    ...mapActions('meta', {
      findMeta: 'find',
      createMeta: 'create',
      resetMeta: 'reset'
    }),

    updateSort (sidx) {
      let { path, query } = this.$route
      query.sidx = sidx
      this.updateRoute({ path, query })
    },

    onTagInputChange (val) {
      let { path, query } = this.$route
      query.tag = val
      this.updateRoute({ path, query })
    },

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    // game input 用
    onGameInputChange (val) {
      let { path, query } = this.$route
      query.game = val
      this.updateRoute({ path, query })
    },

    // metatype input 用
    onMetaTypeChange (val) {
      let { path, query } = this.$route
      query.type = val
      this.updateRoute({ path, query })
    },

    onMetaCategoryChange (val) {
      let { path, query } = this.$route
      query.category = val
      this.updateRoute({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { search, sidx } = query
      search = search === '' ? null : search
      sidx = sidx === '' ? 'data.id' : sidx

      if (reset) {
        this.resetMeta({ search, sidx })
      }
      if (this.isMetaBusy) return
      this.findMeta({ search, sidx, type: this.type })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      const { search, sidx } = this.$route.query
      this.search = search || ''
      this.sidx = sidx || 'data.id'
      this.fetchData(true)
    },

    showMetaEditor () {
      const data = {
        id: '',
        name: '',
        description: '',
        rate: 1,
        meta: '',
        unlock: {
          price: 0,
          gem: 0,
          actPoint: 0,
          laijiCoin: 0,
          level: 0,
          note: ''
        }
      }
      if (this.type === 'avatar') data.winAnimation = ''
      if (this.type !== 'animation') data.thumbnail = ''
      // const { totalRate, data: { rate } } = this.metaList[0]
      this.metaData = {
        data,
        totalRate: 0
      }

      this.metaEditorVisible = true
    },
    async onMetaCreate () {
      if (!this.metaData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeMetaEditor()
      }
      let data = _.pick(this.metaData, ['key', 'data', 'thumbnail'])
      data.type = this.type
      let meta = data.data.meta
      if (meta) {
        data.data.meta = JSON.parse(meta)
      }

      let [err] = await to(this.createMeta({ data }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create meta error'))
      }
      this.$Message.success(this.$t('Create meta successful'))
      this.closeMetaEditor()
      this.fetchData(true)
    },

    closeMetaEditor () {
      this.metaEditorVisible = false
      setTimeout(() => {
        this.metaData = null
      }, 500)
    }
  },
  async mounted () {
    if (this.sidx === '') this.sidx = 'data.id'
    await this.resetSelector()
  },
  beforeDestroy () {
  }
}

</script>

<style lang="sass">
.sort-method
  float: left
  margin-left: 8px
</style>
