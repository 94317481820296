<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t('Add') : $t('Edit') }}

    Form.modal-editor(
      :model="formData"
      ref="metaEditorForm"
      :rules="metaEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="data.id")
            Row
              Col.title(span="9")
                h3 {{$t('ID')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.data.id"
                )
          FormItem(prop="data.name")
            Row
              Col.title(span="9")
                h3 {{$t('Name')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.data.name"
                  :placeholder="$t('Please input name')"
                )

          FormItem(prop="data.description")
            Row
              Col.title(span="9")
                h3 {{$t('Description')}}
              Col(span="14" offset="1")
                Input(
                  type="textarea"
                  v-model="formData.data.description"
                  :placeholder="$t('Please input avatar description')"
                )
        .content-part
          Divider(orientation="left")
              Icon(
                type="md-create",
                style="margin-right: 6px"
              )
              span {{ $t('Unlock info') }}

          FormItem(prop="data.rate")
            Row
              Col.title(span="9")
                h3 {{$t('Item rate')}}
              Col(span="14" offset="1")
                InputNumber(
                  long
                  :step="1"
                  v-model="formData.data.rate"
                  controls-outside
                )
                span(
                  style="margin-left: 16px"
                ) {{ (formData.data.rate / (formData.totalRate + formData.data.rate) * 100).toFixed(2) }}%

          FormItem(prop="data.unlock.price")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock price')}}
              Col(span="14" offset="1")
                InputNumber(
                  :step="10000"
                  v-model="formData.data.unlock.price"
                  controls-outside
                )

          FormItem(prop="data.unlock.gem")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock gem')}}
              Col(span="14" offset="1")
                InputNumber(
                  :step="10"
                  v-model="formData.data.unlock.gem"
                  controls-outside
                )

          FormItem(prop="data.unlock.actPoint")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock actPoint')}}
              Col(span="14" offset="1")
                InputNumber(
                  :step="10"
                  v-model="formData.data.unlock.actPoint"
                  controls-outside
                )

          FormItem(prop="data.unlock.laijiCoin")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock laijiCoin')}}
              Col(span="14" offset="1")
                InputNumber(
                  :step="10"
                  v-model="formData.data.unlock.laijiCoin"
                  controls-outside
                )

          FormItem(prop="data.unlock.level")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock level')}}
              Col(span="14" offset="1")
                Input(
                  v-model="formData.data.unlock.level"
                  controls-outside
                )

          FormItem(prop="data.unlock.note")
            Row
              Col.title(span="9")
                h3 {{$t('Unlock note')}}
              Col(span="14" offset="1")
                Input(
                  type="textarea"
                  v-model="formData.data.unlock.note"
                  :placeholder="$t('Please input unlock note')"
                )

          FormItem(prop="data.meta")
            Row
              Col.title(span="9")
                h3 {{$t('Meta')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.data.meta"
                  type="textarea"
                  :rows="4"
                )

          FormItem(prop="data.winAnimation" v-if="type === 'avatar'")
            Row
              Col.title(span="9")
                h3 {{$t('Win animation')}}
              Col(span="14" offset="1")
                Select(
                  filterable
                  v-model="formData.data.winAnimation"
                )
                  Option(
                    v-for="(myAni, i) in winAnimationList"
                    :value="myAni.key"
                    :key="`${i}-${myAni.key}`"
                  ) {{ winAnimationName(myAni.key) }}

          FormItem(prop="images")
            Row
              Col.title(span="9")
                h3 {{$t('Upload image')}}

              Col(span="7" offset="8")
                Upload.uploader(
                  :accept="acceptFormats"
                  :show-upload-list="false"
                  :headers="uploadHeaders"
                  :action="uploadUrl"
                  :on-progress="onProgress('thumbnail')"
                  :on-success="onSuccess('thumbnail')"
                  :on-error="onError('thumbnail')"
                  :format="fileFormats"
                )
                  Button.upload-btn(
                    long
                    :loading="uploading.thumbnail"
                    :type="formData.thumbnail ? 'primary' : 'default'"
                    ) {{ getFileName('thumbnail') || $t('Upload image') }}

              Col(span="7" offset="17" v-if="type === 'theme'")
                Upload.uploader(
                  :accept="acceptFormats"
                  :show-upload-list="false"
                  :headers="uploadHeaders"
                  :action="uploadUrl"
                  :on-progress="onProgress('thumbnail2')"
                  :on-success="onSuccess('thumbnail2')"
                  :on-error="onError('thumbnail2')"
                  :format="fileFormats"
                )
                  Button.upload-btn(
                    long
                    :loading="uploading.thumbnail2"
                    :type="formData.thumbnail2 ? 'primary' : 'default'"
                    ) {{ getFileName('thumbnail2') || $t('Upload image of Character') }}

              Col(span="7" offset="17" v-if="type === 'theme'")
                Upload.uploader(
                  :accept="acceptFormats"
                  :show-upload-list="false"
                  :headers="uploadHeaders"
                  :action="uploadUrl"
                  :on-progress="onProgress('thumbnail3')"
                  :on-success="onSuccess('thumbnail3')"
                  :on-error="onError('thumbnail3')"
                  :format="fileFormats"
                )
                  Button.upload-btn(
                    long
                    :loading="uploading.thumbnail3"
                    :type="formData.thumbnail3 ? 'primary' : 'default'"
                    ) {{ getFileName('thumbnail3') || $t('Upload image of BG') }}

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import FileUpload from '~m/file-upload'
export default {
  name: 'meta-editor',
  mixins: [FileUpload],
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  data () {
    return {
      isUpdating: false
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('animation', {
      winAnimationList: 'getAllForSelector'
    }),
    metaEditorRule () {
      return {
        'data.id': [{ required: true, message: this.$t('id'), trigger: 'blur' }],
        'data.name': [{ required: true, message: this.$t('name'), trigger: 'blur' }]
      }
    },
    type () {
      const path = this.$route.path.split('/')
      return path[path.length - 1]
    }
  },
  methods: {
    ...mapActions('animation', {
      findAllAnimation: 'findAll'
    }),
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
    },
    winAnimationName (key) {
      for (const ani of this.winAnimationList) {
        if (ani.key === key) return ani.name
      }
    },
    verifySelector (field) {
      return (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t(`Please select avatar ${field}`)))
        }
        callback()
      }
    },
    verifyNumber (rule, value, callback) {
      if (!value && value * 1 !== 0) {
        return callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value) && value * 1 !== 0) {
        return callback(new Error(this.$t(`Input number`)))
      }
      callback()
    },
    handleSubmit () {
      this.$refs.metaEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {
    const wl = this.winAnimationList || []
    if (!wl.length) await this.findAllAnimation()
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.meta
  padding: 0 10px
.uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

.upload-btn
  width: 130px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
</style>
