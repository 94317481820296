<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      fileFormats: ['png', 'jpg', 'jpeg'],
      acceptFormats: '.png,.jpg,.jpeg',
      uploading: {
        image: false,
        thumbnail: false,
        thumbnail2: false,
        thumbnail3: false
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['userInfo']),
    uploadUrl () {
      return `${process.env.VUE_APP_API}/upload`
    },

    uploadHeaders () {
      return {
        'X-Token': this.userInfo.token
      }
    }
  },
  methods: {
    onProgress (path) {
      return (event, file, fileList) => {
        this.uploading[path] = true
      }
    },

    onSuccess (path) {
      return (response, file, fileList) => {
        this.uploading[path] = false
        this.formData[path] = response.data
      }
    },

    onError (path) {
      return (error, file, fileList) => {
        this.uploading[path] = false
        this.formData[path] = null
        console.log(error)
      }
    },

    getFileName (path) {
      if (!path) return null
      return _.get(this.formData[path], 'name')
    }
  }
}
</script>
